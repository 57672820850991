import React from "react";
import styles from "./ReportSection.module.css";

interface ReportSectionProps {
  title: string;
  subtitle: string;
}

const ReportSection: React.FC<ReportSectionProps> = ({ title, subtitle }) => {
  return (
    <div className={styles.headerContainer}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.subtitleContainer}>
        <p className={styles.subtitle}>{subtitle}</p>
        <div className={styles.labelContainer}>
          <span className={`${styles.label} ${styles.danger}`}>위험</span>
          <span className={`${styles.label} ${styles.warning}`}>경계</span>
          <span className={`${styles.label} ${styles.caution}`}>주의</span>
          <span className={`${styles.label} ${styles.interest}`}>관심</span>
          <span className={`${styles.label} ${styles.safe}`}>안전</span>
        </div>
      </div>
    </div>
  );
};

export default ReportSection;
