import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, ChartOptions, ChartData } from "chart.js";

// ArcElement 등록
Chart.register(ArcElement);

const GaugeChart: React.FC = () => {
  const value = 49.55; // 표시할 값

  // value 값에 따른 색상 설정 함수
  const getColorByValue = (value: number): string => {
    if (value <= 20) return "#ff4d4d";
    if (value <= 40) return "#ffaa00";
    if (value <= 60) return "#ffd700";
    if (value <= 80) return "#87ceeb";
    return "#66cdaa";
  };

  const color = getColorByValue(value);

  const data: ChartData<"doughnut"> = {
    labels: ["", ""],
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [color, "#e0e0e0"],
        borderColor: "#ffffff",
        borderWidth: 4,
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    maintainAspectRatio: false,
    cutout: "70%", // 중앙 구멍 크기 조절
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
      datalabels: { display: false },
    },
  };

  return (
    <div style={{ position: "relative", width: "200px", height: "200px" }}>
      <Doughnut data={data} options={options} />

      {/* 중앙 퍼센트 텍스트 */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "24px",
          color: color,
          fontWeight: "bold",
        }}
      >
        {value}%
      </div>
    </div>
  );
};

export default GaugeChart;
