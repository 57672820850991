// DoughnutGradeBarChart.tsx
import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  ChartOptions,
  Chart,
  Plugin,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Title, ChartDataLabels);

const DoughnutGradeBarChart: React.FC = () => {
  const data = {
    labels: ["위험", "경계", "주의", "관심", "안전"],
    datasets: [
      {
        data: [48, 126, 804, 75, 44],
        backgroundColor: [
          "#ff4d4d",
          "#ffaa00",
          "#ffd700",
          "#87ceeb",
          "#66cdaa",
        ],
        borderColor: "#ffffff",
        borderWidth: 2,
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    maintainAspectRatio: false,
    cutout: "0%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${context.raw}명`,
        },
      },
      title: {
        display: true,
        text: "학생 분포도",
        font: {
          size: 14,
          family: "Pretendard", // Title font 설정
        },
        padding: {
          top: 20,
          bottom: 0, // Increase this value to add more space below the title
        },
      },
      datalabels: {
        display: false,
      },
    },
  };

  // Custom plugin to draw connector lines and display values outside the chart
  const drawConnectorLinesPlugin: Plugin<"doughnut"> = {
    id: "drawConnectorLines",
    afterDraw(chart: Chart<"doughnut">) {
      const { ctx, chartArea } = chart;
      const { left, right, top, bottom } = chartArea;
      const halfWidth = (left + right) / 2;
      const halfHeight = (top + bottom) / 2;

      chart.data.datasets[0].data.forEach((value, index) => {
        const meta = chart.getDatasetMeta(0);
        const dataPoint = meta.data[index] as ArcElement;

        // Ensure startAngle, endAngle, and outerRadius are available
        const angle = (dataPoint.startAngle + dataPoint.endAngle) / 2;
        const x = halfWidth + Math.cos(angle) * dataPoint.outerRadius;
        const y = halfHeight + Math.sin(angle) * dataPoint.outerRadius;

        // Calculate end position of the connector line based on the quadrant
        const xLine = x >= halfWidth ? x + 10 : x - 10;
        const yLine = y >= halfHeight ? y + 10 : y - 10;
        const extraLine = x >= halfWidth ? 2 : -2;

        // Draw connector line in the color of the segment
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(xLine, yLine);
        ctx.lineTo(xLine + extraLine, yLine);

        const backgroundColor = chart.data.datasets[0].backgroundColor;
        if (Array.isArray(backgroundColor)) {
          ctx.strokeStyle = backgroundColor[index] as string;
          ctx.fillStyle = backgroundColor[index] as string;
        } else {
          ctx.strokeStyle = "rgb(42, 43, 46)";
          ctx.fillStyle = "rgb(42, 43, 46)";
        }
        ctx.stroke();

        // Display the label with value
        const label = chart.data.labels ? chart.data.labels[index] : "";
        ctx.fillStyle = "#333";
        ctx.font = "12px Pretendard, sans-serif"; // Pretendard 폰트와 14px 크기 설정
        ctx.textAlign = x >= halfWidth ? "left" : "right";
        ctx.textBaseline = "middle";
        ctx.fillText(`${label} : ${value}명`, xLine + extraLine * 1.2, yLine); // Adjust position for spacing
      });
    },
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Doughnut
        data={data}
        options={options}
        plugins={[drawConnectorLinesPlugin]}
      />
    </div>
  );
};

export default DoughnutGradeBarChart;
