import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title, // Title을 추가로 불러오기
  ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title, // Title 등록
  ChartDataLabels
);

const HorizontalBarChart: React.FC = () => {
  const data = {
    labels: ["1학년", "2학년", "3학년", "4학년", "5학년", "6학년"],
    datasets: [
      {
        data: [0, 0, 0, 0, 0, 39],
        backgroundColor: "#ff4d4d",
        barPercentage: 1,
      },
      {
        data: [17, 14, 23, 27, 21, 24],
        backgroundColor: "#ffaa00",
        barPercentage: 1,
      },
      {
        data: [142, 148, 114, 142, 141, 117],
        backgroundColor: "#ffd700",
        barPercentage: 1,
      },
      {
        data: [19, 16, 7, 5, 10, 18],
        backgroundColor: "#87ceeb",
        barPercentage: 1,
      },
      {
        data: [10, 8, 8, 5, 13, 0],
        backgroundColor: "#66cdaa",
        barPercentage: 1,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        max: 200,
        ticks: {
          stepSize: 50, // x축 간격을 50으로 설정
        },
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw}명`,
        },
      },
      datalabels: {
        anchor: "end",
        align: "end",
        formatter: (value) => `${value}명`,
        color: "#333",
        font: {
          weight: "bold",
          size: 12,
          family: "Pretendard", // Title font 설정
        },
      },
      title: {
        display: true,
        text: "학년별 학생 분포도", // 차트 제목 텍스트
        font: {
          size: 14,
          family: "Pretendard", // Title font 설정
        },
        color: "#333", // 제목 색상
        padding: {
          top: 20,
          bottom: 10,
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;
