import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const HorizontalBarChart: React.FC = () => {
  const data = {
    labels: ["자연재난", "사회재난", "생활안전"],
    datasets: [
      {
        label: "위험",
        data: [57, 54, 54],
        backgroundColor: "#ff4d4d",
      },
      {
        label: "경계",
        data: [175, 466, 94],
        backgroundColor: "#ffaa00",
      },
      {
        label: "주의",
        data: [534, 412, 290],
        backgroundColor: "#ffd700",
      },
      {
        label: "관심",
        data: [271, 125, 94],
        backgroundColor: "#87ceeb",
      },
      {
        label: "안전",
        data: [60, 40, 572],
        backgroundColor: "#66cdaa",
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    indexAxis: "y", // 수평 막대 차트를 생성하기 위한 옵션
    scales: {
      x: {
        beginAtZero: true,
        max: 700,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw}명`,
        },
      },
      datalabels: {
        anchor: "end",
        align: "end",
        formatter: (value) => `${value}명`,
        color: "#333",
        font: {
          weight: "bold",
          size: 12,
          family: "Pretendard", // Title font 설정
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;
