import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Title,
  ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Title, // Title 등록
  ChartDataLabels
);

// 배경 색상을 추가하는 커스텀 플러그인
const backgroundColorPlugin = {
  id: "backgroundColorPlugin",
  beforeDraw: (chart: ChartJS) => {
    const { ctx, chartArea, scales } = chart;
    const { left, right } = chartArea;
    const y = scales.y as any;

    const ranges = [
      { max: 20, color: "rgba(255, 77, 77, 0.2)" },
      { max: 40, color: "rgba(255, 170, 0, 0.2)" },
      { max: 60, color: "rgba(255, 215, 0, 0.2)" },
      { max: 80, color: "rgba(135, 206, 235, 0.2)" },
      { max: 100, color: "rgba(102, 205, 170, 0.2)" },
    ];

    ranges.forEach((range) => {
      const yPosStart = y.getPixelForValue(range.max - 20);
      const yPosEnd = y.getPixelForValue(range.max);

      ctx.fillStyle = range.color;
      ctx.fillRect(left, yPosEnd, right - left, yPosStart - yPosEnd);
    });
  },
};

const BasicBarChart: React.FC = () => {
  const dataValues = [54.01, 52.46, 50.45, 49.14, 49.47, 42.37];

  const getColor = (value: number): string => {
    if (value <= 20) return "#ff4d4d";
    if (value <= 40) return "#ffaa00";
    if (value <= 60) return "#ffd700";
    if (value <= 80) return "#87ceeb";
    return "#66cdaa";
  };

  const data = {
    labels: ["1학년", "2학년", "3학년", "4학년", "5학년", "6학년"],
    datasets: [
      {
        label: "퍼센티지",
        data: dataValues,
        backgroundColor: dataValues.map(getColor),
        barPercentage: 0.5,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 20,
          callback: (value) => `${value}`,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw}%`,
        },
      },
      datalabels: {
        color: "#333",
        anchor: "end",
        align: "end",
        font: {
          weight: "bold",
          size: 12,
          family: "Pretendard", // Title font 설정
        },
        formatter: (value: number) => `${value}`,
      },
      title: {
        display: true,
        text: "학년별 인식도 (%)", // 차트 제목
        font: {
          size: 14,
          family: "Pretendard", // Title font 설정
        },
        color: "#333",
        padding: {
          top: 20,
          bottom: 10,
        },
      },
    },
  };

  return (
    <Bar data={data} options={options} plugins={[backgroundColorPlugin]} />
  );
};

export default BasicBarChart;
