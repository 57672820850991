import React from "react";
import styles from "./ReportSection2.module.css";

interface ReportSectionProps {
  title: string;
}

const ReportSection2: React.FC<ReportSectionProps> = ({ title }) => {
  return (
    <div className={styles.headerContainer}>
      <h1 className={styles.title}>{title}</h1>
    </div>
  );
};

export default ReportSection2;
