// BarChart.tsx
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  CategoryScale,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// 플러그인 등록
ChartJS.register(LinearScale, CategoryScale, BarElement, ChartDataLabels);

const BarChart: React.FC = () => {
  const data = {
    labels: ["위험", "경계", "주의", "관심", "안전"],
    datasets: [
      {
        label: "학생 분포도",
        data: [48, 126, 804, 75, 44],
        backgroundColor: [
          "#ff4d4d",
          "#ffaa00",
          "#ffd700",
          "#87ceeb",
          "#66cdaa",
        ],
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 1500,
        ticks: {
          stepSize: 500,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "#333",
        anchor: "end",
        align: "end",
        font: {
          size: 12,
          weight: "bold",
          family: "Pretendard", // Title font 설정
        },
        formatter: (value: number) => `${value.toLocaleString()}명`,
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "200px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
