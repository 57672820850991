import React from "react";
import { useNavigate } from "react-router-dom";
import dividerStyles from "../../styles/coverPage/CoverPageDivider.module.css";
import styles from "../../styles/reportPage/ReportPageBody.module.css";
import footer from "../../styles/common/CommonFooter.module.css";
import pageStyles from "../../styles/common/CommonPageLayout.module.css";
import ReportSection from "../../components/report/ReportSection";
import ReportSection2 from "../../components/report/ReportSection2";
import GaugeChart from "../../components/charts/GaugeChart";
import BarChart from "../../components/charts/BarChart";
import RadarChart from "../../components/charts/RadarChart";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import BasicBarChart from "../../components/charts/BasicBarChart";
import examButtonStyles from "../../styles/examPage/ExamPageButton.module.css";
import buttonStyles from "../../styles/common/CommonButton.module.css";

const ReportPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/reportGradePage");
  };

  return (
    <div className={pageStyles.coverPage}>
      <header>
        <div className={dividerStyles.divider}>
          <div className={dividerStyles.sectionOrange}></div>
          <div className={dividerStyles.sectionGreen}></div>
          <div className={dividerStyles.sectionBlue}></div>
        </div>
      </header>

      <main className={styles.reportBody}>
        <div className={styles.sectionAndChartsContainer}>
          <ReportSection
            title="종합 결과"
            subtitle="교영 초등학교의 마약 인식도 검사 결과입니다."
          />

          <div className={styles.flexContainer}>
            {/* 종합 - 종합 인식도 */}
            <div className={styles.tableAndChartContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.reportTable}>
                  <thead>
                    <tr>
                      <th className={styles.grayBackground} rowSpan={2}>
                        인원
                      </th>
                      <th className={styles.grayBackground} colSpan={2}>
                        종합 인식도
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.grayBackground}>지수</th>
                      <th className={styles.grayBackground}>평균(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1,097명</td>
                      <td>주의</td>
                      <td>49.55%</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div className={styles.chartContainer}>
                          <GaugeChart />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* 종합 - 학생 분포도 */}
            <div className={styles.tableAndChartContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.reportTable}>
                  <thead>
                    <tr>
                      <th className={styles.grayBackground} colSpan={5}>
                        학생 분포도
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.grayBackground}>위험</th>
                      <th className={styles.grayBackground}>경계</th>
                      <th className={styles.grayBackground}>주의</th>
                      <th className={styles.grayBackground}>관심</th>
                      <th className={styles.grayBackground}>안전</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>48명</td>
                      <td>126명</td>
                      <td>804명</td>
                      <td>75명</td>
                      <td>44명</td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        <div className={styles.chartContainer}>
                          <BarChart />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <ReportSection2 title="영역별 결과" />

          <div className={styles.flexContainer}>
            {/* 영역별 결과 - 영역별 인식도 */}
            <div className={styles.tableAndChartContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.reportTable}>
                  <thead>
                    <tr>
                      <th className={styles.grayBackground} rowSpan={2}>
                        인원
                      </th>
                      <th className={styles.grayBackground} colSpan={2}>
                        종합 인식도
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.grayBackground}>지수</th>
                      <th className={styles.grayBackground}>평균(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>자연재난</td>
                      <td>주의</td>
                      <td>54.44%</td>
                    </tr>
                    <tr>
                      <td>사회재난</td>
                      <td>주의</td>
                      <td>46.43%</td>
                    </tr>
                    <tr>
                      <td>생활안전</td>
                      <td>주의</td>
                      <td>46.43%</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div
                          className={styles.chartContainer}
                          style={{ height: "250px" }}
                        >
                          <RadarChart />
                        </div>
                        <div
                          className={styles.chartContainer}
                          style={{ height: "250px" }}
                        >
                          <BasicBarChart />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* 영역별 결과 - 학생 분포도 */}
            <div className={styles.tableAndChartContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.reportTable}>
                  <thead>
                    <tr>
                      <th className={styles.grayBackground} colSpan={5}>
                        학생 분포도
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.grayBackground}>위험</th>
                      <th className={styles.grayBackground}>경계</th>
                      <th className={styles.grayBackground}>주의</th>
                      <th className={styles.grayBackground}>관심</th>
                      <th className={styles.grayBackground}>안전</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>57명</td>
                      <td>175명</td>
                      <td>534명</td>
                      <td>271명</td>
                      <td>60명</td>
                    </tr>
                    <tr>
                      <td>54명</td>
                      <td>466명</td>
                      <td>412명</td>
                      <td>125명</td>
                      <td>40명</td>
                    </tr>
                    <tr>
                      <td>54명</td>
                      <td>466명</td>
                      <td>412명</td>
                      <td>125명</td>
                      <td>40명</td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        <div
                          className={styles.chartContainer}
                          style={{ height: "500px" }}
                        >
                          <HorizontalBarChart />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className={examButtonStyles.buttonContainer}>
          <button className={buttonStyles.startButton} onClick={handleClick}>
            학년 종합 결과 보기
          </button>
        </div>
      </main>

      <footer className={footer.footer}>
        <div className={footer.footerText}>
          <p>
            Copyright © 2008. Youth Health&Work Foundation All Right Reserved
          </p>
          <p>전화번호 : 02-747-1388</p>
        </div>
      </footer>
    </div>
  );
};

export default ReportPage;
