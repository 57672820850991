import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CoverPage from "./pages/cover/CoverPage";
import ExamPage from "./pages/exam/ExamPage";
import ReportPage from "./pages/report/ReportPage";
import ReportGradePage from "./pages/report/ReportGradePage";
import HonorPage from "./pages/honor/HonorPage";

const router = createBrowserRouter([
  { path: "/", element: <CoverPage /> },
  { path: "/examPage", element: <ExamPage /> },
  { path: "/reportPage", element: <ReportPage /> },
  { path: "/reportGradePage", element: <ReportGradePage /> },
  { path: "/honorPage", element: <HonorPage /> },
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
