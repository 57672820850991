import React from "react";
import styles from "../../styles/honor/HonorPageBody.module.css";
import honorFrame from "../../assets/images/honor_frame.png";

const HonorPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.frameContainer}>
        <img
          src={honorFrame}
          alt="임명장 프레임"
          className={styles.frameImage}
        />
        <div className={styles.content}>
          <h1 className={styles.title}>임명장</h1>
        </div>
      </div>
    </div>
  );
};

export default HonorPage;
